.toggle {
    width: 48px;
    height: 24px;
    padding: 4px;
    border-radius: 24px;
    overflow: hidden;
    align-items: center;
    position: relative;
    transition: all 0.5s;
  }
  
  .toggleContent {
    width: 16px;
    height: 16px;
    background: white;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 9999px;
  }
  
  .toggleLeft {
    padding-left: 4px;
    background: #e3e3e3;
  }
  .toggleRight {
    background: radial-gradient(70% 70% at 80% 75%, #eafaf7 12%, #08e2a7 100%);
    padding-left: 28px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25) inset;
  }
  